import request from "@/utils/request";

// 消息定时群发和草稿列表
export function messageList(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mass/message/list`,
    method: "post",
    data
  });
}
// 取消群发
export function cancelMass(params) {
  return request({
    url: `/api/v1/platform/distribution/web/mass/message/regular/cancel`,
    method: "post",
    params
  });
}
// 删除草稿
export function deleteDraft(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mass/message/del/${data.id}`,
    method: "post",
    data
  });
}
// 发表记录列表顶部统计
export function getRecordCount(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mass/message/record/top/count`,
    method: "post",
    data
  });
}
// 发表记录列表
export function getRecordList(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mass/message/record/list`,
    method: "post",
    data
  });
}
// 删除已发布
export function deleteMessage(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mass/message/record/del/${data.id}`,
    method: "post",
    data
  });
}
// 草稿发布
export function publishMessage(data) {
  return request({
    url: `/api/v1/platform/distribution/web/mass/message/publish`,
    method: "post",
    data
  });
}

// 创建群发消息
export const createMassMessage = data =>
  request({
    url: `/api/v1/platform/distribution/web/mass/message/create`,
    method: "POST",
    data
  });

// 编辑群发消息【草稿】
export const editMassMessage = data =>
  request({
    url: `/api/v1/platform/distribution/web/mass/message/edit`,
    method: "POST",
    data
  });

// 发送次数查询
export const getMassNumber = () =>
  request({
    url: `/api/v1/platform/distribution/web/mass/message/mass/number`,
    method: "GET"
  });

// 获取成员列表
export const getMemberList = data =>
  request({
    url: `/api/v1/platform/web/channel/manage/member/info`,
    method: "POST",
    data
  });

// 创建文档添加到文档库
export const createDoc = data =>
  request({
    url: `/api/v1/platform/distribution/web/mass/message/doc/create`,
    method: "POST",
    data
  });

// 编辑文档
export const editDoc = data =>
  request({
    url: `/api/v1/platform/distribution/web/mass/message/doc/edit`,
    method: "POST",
    data
  });

// 获取文档列表
export const getDocList = data =>
  request({
    url: `/api/v1/platform/distribution/web/mass/message/doc/list`,
    method: "POST",
    data
  });

// 删除文档
export const deleteDoc = params =>
  request({
    url: `/api/v1/platform/distribution/web/mass/message/doc/delete`,
    method: "POST",
    params
  });

// 消息详情【草稿】
export const getMessageDetail = id =>
  request({
    url: `/api/v1/platform/distribution/web/mass/message/detail/${id}`,
    method: "POST"
  });

// 消息详情【正式】
export const getMessageRecordDetail = id =>
  request({
    url: `/api/v1/platform/distribution/web/mass/message/record/detail/${id}`,
    method: "POST"
  });

// 编辑消息【正式】
export const editMassMessageRecord = data =>
  request({
    url: `/api/v1/platform/distribution/web/mass/message/record/edit`,
    method: "POST",
    data
  });
