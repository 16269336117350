<template>
  <div>
    <div class="add-temp">
      <div class="temp-title">新增群发</div>
      <div class="add-btn">
        <div @click="openWindow(1)">
          <i class="el-icon-tickets"></i>图文消息
        </div>
        <div @click="openWindow(3)"><i class="el-icon-edit"></i>文字消息</div>
        <div @click="openWindow(4)">
          <i class="el-icon-picture-outline"></i>图片消息
        </div>
      </div>
    </div>
    <div class="add-temp" v-if="massList?.length">
      <div class="temp-title">定时群发</div>
      <el-table border :data="massList" :show-header="false">
        <el-table-column min-width="50">
          <div slot-scope="{ row }" class="graphic-content">
            <el-image
              v-if="row.cover"
              class="picture"
              :src="row.cover"
              fit="cover"
              :preview-src-list="[row.cover]"
            >
            </el-image>
            <div class="content">
              <div
                v-if="row.publishType == 1"
                :class="{ 'title-hover': row.publishType == 1 }"
                @click="openWindow(5, row.id, row.publishType, row.status)"
              >
                {{ row.title }}
              </div>
              <template v-else-if="row.publishType == 3">
                <input :id="'exp' + row.id" class="exp" type="checkbox" />
                <div class="text-type-content table-text">
                  <label class="btn" :for="'exp' + row.id"></label>
                  {{ row.context.trim() }}
                </div>
              </template>
              <div v-else>[图片消息]</div>
              <div class="color6">
                {{ row.lastEditUserName || row.inUserName }}，{{
                  (row.lastEditDate || row.inDate) | formatDate(15)
                }}
              </div>
            </div>
          </div>
        </el-table-column>
        <el-table-column min-width="30">
          <template slot-scope="{ row }">
            <div>定时群发</div>
            <div class="color6">
              定时时间：{{ row.regularDate | formatDate(15) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="150">
          <div slot-scope="{ row }">
            <el-popover
              placement="bottom-end"
              width="290"
              trigger="click"
              :ref="'massPop' + row.id"
            >
              <div class="hand-popover">
                取消后，本条消息将进入草稿箱，不会被推送，群发条数也将恢复。
                <div class="cancel-btn">
                  <el-button @click="handleCancelPop('massPop' + row.id)"
                    >取消</el-button
                  >
                  <el-button
                    type="danger"
                    @click="handleCancelMass(row.id, 'massPop' + row.id)"
                    >确定</el-button
                  >
                </div>
              </div>
              <el-button type="text" slot="reference">取消群发</el-button>
            </el-popover>
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="add-temp" v-if="draftList?.length">
      <div class="temp-title">草稿箱</div>
      <div class="scroll-box relative">
        <button
          type="button"
          class="el-carousel__arrow el-carousel__arrow--left"
          @click="scrollLeft"
        >
          <i class="el-icon-arrow-left"></i>
        </button>
        <button
          type="button"
          class="el-carousel__arrow el-carousel__arrow--right"
          @click="scrollRight"
        >
          <i class="el-icon-arrow-right"></i>
        </button>
        <el-row
          @mousedown.native="e => mouseStart(e)"
          @mouseup.native="mouseStop"
          @mousemove.native="mouseMove"
          @mouseleave.native="mouseStop"
          ref="draftRow"
          :gutter="30"
          type="flex"
          class="draft-list"
        >
          <el-col :span="8" v-for="(item, index) in draftList" :key="index">
            <div
              class="draft-li"
              :class="{
                'text-li': item.publishType == 3,
                'picture-li': item.publishType == 4
              }"
              @click.stop="
                openWindow(5, item.id, item.publishType, item.status)
              "
              @mouseleave="handleCancelPop('draft' + item.id)"
            >
              <div class="handle-btn">
                <el-popover
                  :placement="
                    index == draftList.length - 1
                      ? 'bottom-end'
                      : 'bottom-start'
                  "
                  width="290"
                  trigger="click"
                  :ref="'draft' + item.id"
                  :append-to-body="false"
                  :popper-options="{ boundariesElement: 'body' }"
                  @click.native.stop
                >
                  <div class="hand-popover">
                    删除该消息后将无法恢复，确定要删除该消息吗？
                    <div class="cancel-btn">
                      <el-button @click="handleCancelPop('draft' + item.id)"
                        >取消</el-button
                      >
                      <el-button
                        type="danger"
                        @click="handleDeleteDraft(item.id, 'draft' + item.id)"
                        >确定</el-button
                      >
                    </div>
                  </div>
                  <el-button slot="reference" class="mr10 h-btn"
                    ><i class="el-icon-delete"></i
                  ></el-button>
                </el-popover>
                <el-button
                  class="h-btn"
                  @click.stop="openWindow(item.publishType, item.id)"
                  ><i class="el-icon-edit-outline"></i
                ></el-button>
              </div>
              <template v-if="item.publishType == 1">
                <el-image fit="cover" :src="item.cover">
                  <i slot="error" class="el-icon-picture"></i>
                </el-image>
                <div class="li-main">
                  <div class="fs16 text">{{ item.title }}</div>
                  <div
                    class="li-content text"
                    v-html="item.summary || item.context"
                  ></div>
                </div>
              </template>
              <template v-if="item.publishType == 3">
                <input :id="'exp' + item.id" class="exp" type="checkbox" />
                <div class="text-type-content">
                  <label class="btn" :for="'exp' + item.id"></label>
                  {{ item.context }}
                </div>
              </template>
              <template v-if="item.publishType == 4">
                <el-image
                  fit="cover"
                  :src="item.cover"
                  :preview-src-list="[item.cover]"
                >
                  <i slot="error" class="el-icon-picture"></i>
                </el-image>
                <div class="li-main">
                  <div class="fs16 mb12">图片消息</div>
                </div>
              </template>
              <div class="in-user">
                {{ item.lastEditUserName || item.inUserName }}，{{
                  (item.lastEditDate || item.inDate) | formatDate(14)
                }}
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
    <div class="add-temp">
      <div class="temp-title">发表记录</div>
      <p class="color9">
        发表记录包括群发和发布。已群发内容会推送给用户。已发布内容不会推送，不占用群发次数，仅适用于引用、自动回复等场景。
      </p>
      <p class="color9">群发权限：{{ messageTotal }}条/天</p>
      <div class="filter">
        <div class="filter-tab">
          <div
            v-for="(item, index) in filterTab"
            :key="index"
            :class="{ active: filterIndex == index }"
            @click="handleSearchType(item.value, index)"
          >
            {{ item.text }} {{ item.count }}
          </div>
        </div>
        <el-input
          placeholder="输入标题/关键词"
          class="f-search-bar"
          v-model="queryData.keyword"
        >
          <el-button slot="append" @click="searchRecord">搜索</el-button>
        </el-input>
      </div>
      <el-table border :data="messageList">
        <el-table-column label="内容" min-width="50">
          <div slot-scope="{ row }" class="graphic-content">
            <el-image
              v-if="row.cover"
              class="picture"
              :src="row.cover"
              fit="cover"
              :preview-src-list="[row.cover]"
            >
            </el-image>
            <div class="content">
              <div
                v-if="row.publishType == 1"
                class="pointer"
                :class="{ 'title-hover': row.publishType == 1 }"
                @click="openWindow(5, row.id, row.publishType, row.status)"
              >
                {{ row.title }}
              </div>
              <template v-else-if="row.publishType == 3">
                <input :id="'exp' + row.id" class="exp" type="checkbox" />
                <div class="text-type-content table-text">
                  <label class="btn" :for="'exp' + row.id"></label>
                  {{ row.context.trim() }}
                </div>
              </template>
              <div v-else>[图片消息]</div>
              <div class="color6">
                {{ row.inUserName }}，{{ row.inDate | formatDate(14) }}
              </div>
            </div>
          </div>
        </el-table-column>
        <el-table-column label="状态" min-width="30">
          <template slot-scope="{ row }">
            <div>{{ row.dataType == 1 ? "已群发" : "已发布" }}</div>
            <div class="color6">
              <span v-if="row.dataType == 1"
                >发送对象：{{ row.groupMass ? "分组群发" : "全部用户" }}</span
              >
              <span v-else>不推送</span>
            </div>
            <div class="color6" v-if="row.dataType == 1">
              发送成功{{ row.successNumber }}人， 发送失败{{ row.failNumber }}人
            </div>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200">
          <div slot-scope="{ row }">
            <template v-if="row.publishType == 1">
              <el-button
                type="text"
                @click="
                  openWindow(row.publishType, row.id, undefined, row.status)
                "
                >编辑</el-button
              >
              <el-button type="text" class="mr20" @click="copyUrl(row)"
                >复制链接</el-button
              >
              <el-popover
                placement="bottom-end"
                width="290"
                trigger="click"
                :ref="'message' + row.id"
              >
                <div class="hand-popover">
                  删除后，服务窗消息列表仍旧保留，文章详情页将无法继续浏览。
                  <div class="cancel-btn">
                    <el-button @click="handleCancelPop('message' + row.id)"
                      >取消</el-button
                    >
                    <el-button
                      type="danger"
                      @click="handleDeleteMessage(row.id, 'message' + row.id)"
                      >确定</el-button
                    >
                  </div>
                </div>
                <el-button slot="reference" type="text" class="red"
                  >删除</el-button
                >
              </el-popover>
            </template>
            <span v-else>-</span>
          </div>
        </el-table-column>
      </el-table>
      <el-pagination
        class="center"
        @size-change="pageSizeChange"
        @current-change="pageNumChange"
        :current-page.sync="queryData.pageNum"
        :page-size="queryData.pageSize"
        layout="total,sizes, prev, pager, next,jumper"
        :total="total"
        prev-text="上一页"
        next-text="下一页"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import {
  messageList,
  cancelMass,
  deleteDraft,
  getRecordCount,
  getRecordList,
  deleteMessage,
  getMassNumber
} from "@/api/message";
import { copyText } from "@/utils";
import { encrypt } from "@/utils/crypto";
import { PCN_H5_URL } from "@/config";
export default {
  data() {
    return {
      massList: [],
      draftList: [],
      filterTab: [
        { text: "全部", count: 0, key: "allCounts", value: "" },
        { text: "群发", count: 0, key: "massHair", value: 1 },
        { text: "发布", count: 0, key: "publish", value: 2 }
      ],
      queryData: { keyword: "", pageNum: 1, pageSize: 10 },
      filterIndex: "",
      messageList: [],
      total: 3,
      //滑动相关字段
      x: 0,
      moveLeft: 0,
      move: false,
      down: false,
      messageTotal: 0
    };
  },
  mounted() {
    document.addEventListener("visibilitychange", this.onshow);
    this.getData();
  },
  beforeDestroy() {
    document.removeEventListener("visibilitychange", this.onshow);
  },
  methods: {
    //打开新增、编辑(1图文，3文本，4图片,5预览图文)
    openWindow(type, id = "", publishType, status) {
      let url = "",
        query = id ? `?id=${encrypt(id)}` : "";
      if (status) {
        query += `&status=${encrypt(status)}`;
      }
      if (type == 1) {
        url = `/distribution/appManage/message/graphicEdit${query}`;
      } else if (type == 3) {
        url = `/distribution/appManage/message/textEdit${query}`;
      } else if (type == 4) {
        url = `/distribution/appManage/message/imageEdit${query}`;
      } else if (type == 5) {
        if (publishType != 1) return;
        url = `/distribution/appManage/message/graphicView${query}`;
      }
      this.$openWindow(url);
    },
    // 新增群发 为this.$openWindow打开新窗口，所以编辑完返回这个需要刷新列表数据
    onshow() {
      if (document.visibilityState === "visible") {
        // 调用请求数据方法
        this.getData();
      }
    },
    //获取全部列表
    getData() {
      this.getMessageList("massList", 1);
      this.getMessageList("draftList", 2);
      this.getMassNumber();
      this.getRecordCount();
      this.getRecordList();
    },
    //获取定时群发和草稿列表
    async getMessageList(arr, status) {
      let params = { pageNum: 1, pageSize: 1000, status: status };
      if (status == 1) params = { ...params, regularMass: 1 }; //regularMass=1表示请求的是定时群发
      const res = await messageList(params);
      if (res) {
        this[arr] = res.list.map(item => {
          return {
            ...item,
            context: item.context.replace(/<img[^<>]+>/gi, "")
          };
        });
      }
    },
    //获取可群发总数量
    async getMassNumber() {
      const res = await getMassNumber();
      if (res) {
        this.messageTotal = res.total;
      }
    },
    //获取发表记录列表顶部统计
    async getRecordCount() {
      const res = await getRecordCount({ keyword: this.queryData.keyword });
      if (res) {
        this.filterTab = this.filterTab.map(item => {
          return {
            ...item,
            count: res[item.key]
          };
        });
      }
    },
    //获取发表记录列表
    async getRecordList() {
      const res = await getRecordList(this.queryData);
      if (res) {
        this.messageList = res.list;
        this.total = res.total;
      }
    },
    //分页
    pageSizeChange(val) {
      this.queryData.pageSize = val;
      this.getRecordList();
    },
    pageNumChange(val) {
      this.queryData.pageNum = val;
      this.getRecordList();
    },
    //筛选发布类型
    handleSearchType(value, index) {
      this.filterIndex = index;
      this.queryData.dataType = value;
      this.getRecordList();
    },
    //搜索发布类型
    searchRecord() {
      this.getRecordCount();
      this.getRecordList();
    },
    //取消群发
    async handleCancelMass(id, pop) {
      const res = await cancelMass({ id: id, regularCancel: true });
      if (res) {
        this.getMessageList("massList", 1);
        this.getMessageList("draftList", 2);
        this.showMessage(pop);
      }
    },
    //删除草稿
    async handleDeleteDraft(id, pop) {
      const res = await deleteDraft({ id: id });
      if (res) {
        this.getMessageList("draftList", 2);
        this.showMessage(pop);
      }
    },
    //删除已发布
    async handleDeleteMessage(id, pop) {
      const res = await deleteMessage({ id: id });
      if (res) {
        this.getRecordList();
        this.showMessage(pop);
      }
    },
    //关闭二次确认框
    handleCancelPop(pop) {
      Object.prototype.toString.call(this.$refs[pop]) === "[object Object]"
        ? this.$refs[pop].doClose()
        : this.$refs[pop][0].doClose();
    },
    // 消息提示
    showMessage(pop) {
      this.$message({
        type: "success",
        message: "操作成功!"
      });
      this.handleCancelPop(pop);
    },
    //草稿箱列表滑动
    mouseStart(e) {
      if (!this.move) {
        this.move = false;
        this.down = true;
        this.x = e.clientX;
        this.moveLeft = this.$refs.draftRow.$el.scrollLeft;
      }
    },
    mouseStop() {
      this.move = false;
      this.down = false;
    },
    mouseMove(e) {
      if (this.down) {
        this.move = true;
        let x = this.x - e.clientX;
        this.$refs.draftRow.$el.scrollLeft = this.moveLeft + x;
      }
    },
    scrollLeft() {
      this.$refs.draftRow.$el.scrollLeft =
        this.$refs.draftRow.$el.scrollLeft - 300;
    },
    scrollRight() {
      this.$refs.draftRow.$el.scrollLeft =
        this.$refs.draftRow.$el.scrollLeft + 300;
    },
    //复制链接
    copyUrl(row) {
      // 伪加密
      const query = encodeURIComponent(`id=${row.id}`);
      let url = `${PCN_H5_URL}/pages/message/detail?q=${query}`;
      copyText(url);
    }
  }
};
</script>

<style scoped lang="scss">
.add-temp {
  background: #fff;
  border-radius: 4px;
  padding: 20px 30px;
  margin-bottom: 10px;
  .temp-title {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: bold;
  }
  .add-btn {
    @include flex-yc;
    & > div {
      width: 185px;
      height: 81px;
      border: solid 1px #eee;
      font-size: 15px;
      margin-right: 10px;
      cursor: pointer;
      border-radius: 4px;
      @include flex-xyc;
      i {
        font-size: 22px;
        margin-right: 4px;
      }
      &:hover {
        color: $blue;
      }
    }
  }
  .scroll-box {
    .el-carousel__arrow {
      border: none;
      outline: none;
      padding: 0;
      margin: 0;
      height: 36px;
      width: 36px;
      cursor: pointer;
      transition: 0.3s;
      border-radius: 50%;
      background-color: rgba(31, 45, 61, 0.31);
      color: #fff;
      position: absolute;
      top: 50%;
      z-index: 10;
      transform: translateY(-50%);
      text-align: center;
      font-size: 16px;
      opacity: 0;
    }
    .el-carousel__arrow--left {
      left: 0;
    }
    .el-carousel__arrow--right {
      right: 0;
    }
    &:hover .el-carousel__arrow--left {
      transform: translateY(-50%) translateX(6px);
      opacity: 1;
    }
    &:hover .el-carousel__arrow--right {
      transform: translateY(-50%) translateX(-6px);
      opacity: 1;
    }
  }
  .graphic-content {
    display: flex;
    .picture {
      max-width: 180px;
      max-height: 90px;
      display: inline-block;
      margin-right: 20px;
    }
    .content {
      padding: 4px 0;
      margin-right: 80px;
      flex: 1;
      @include flex-yb;
      & > div:first-child,
      .table-text {
        font-size: 15px;
        margin-bottom: 22px;
      }
      .title-hover:hover {
        color: $blue;
      }
      .text-type-content {
        -webkit-line-clamp: 3;
      }
      .exp:checked + .text-type-content .btn::before {
        content: "[收起]";
      }
    }
  }
  .draft-list {
    overflow-x: auto;
    .el-col {
      flex-shrink: 0;
      .draft-li {
        border: solid 1px $grayBg;
        position: relative;
        border-radius: 8px;
        user-select: none;
        height: 100%;
        @include flex-yb;
        .handle-btn {
          position: absolute;
          right: 16px;
          top: 18px;
          z-index: 99;
          opacity: 0;
          @include flex-yc;
          .h-btn {
            width: 32px;
            height: 32px;
            padding: 0;
            border-radius: 50%;
            color: #666;
            i {
              font-size: 16px;
            }
          }
          .publish-btn {
            width: 65px;
            border-radius: 20px;
          }
        }
        .el-image {
          width: 100%;
          height: 160px;
          border-radius: 8px 8px 0 0;
          font-size: 40px;
          background: #eee;
          color: #999;
          @include flex-xyc;
          ::v-deep img {
            -webkit-user-drag: none;
          }
        }
        .li-main {
          padding: 20px 20px 0;
          .li-content {
            color: #666;
            margin: 14px 0;
          }
          .text {
            @include text-clamp(2);
          }
        }
        .in-user {
          color: #999;
          padding: 0 20px 20px;
        }
        &:hover .handle-btn {
          opacity: 1;
        }
      }
      /*草稿文本*/
      .text-li {
        .text-type-content {
          font-size: 16px;
          line-height: 25px;
          margin: 20px 0;
          padding: 0 20px;
          -webkit-line-clamp: 10;
        }
        .exp:checked + .text-type-content::after {
          display: none;
        }
        .exp:checked + .text-type-content .btn::before {
          content: "";
        }
        .exp:checked + .text-type-content {
          height: 250px;
          overflow-y: auto;
        }
      }
      /*草稿图片*/
      .picture-li .el-image {
        height: 238px;
      }
    }
    &::-webkit-scrollbar {
      height: 6px;
      display: none;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }
    &::-webkit-scrollbar-track {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.1);
    }
  }
  .filter {
    margin: 10px 0 20px;
    @include flex-ycb;
    .filter-tab {
      @include flex-yc;
      div {
        width: 85px;
        height: 35px;
        line-height: 35px;
        text-align: center;
        background: #f2f2f2;
        border-radius: 2px;
        color: #666;
        margin-right: 10px;
        cursor: pointer;
        &.active {
          color: #3470fc;
          background: #ebf1ff;
        }
      }
    }
    .el-input {
      width: 320px;
    }
  }
  /*文字展开与收缩*/
  .text-type-content {
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: justify;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    position: relative;
    white-space: pre-line;
    word-break: break-all;
  }
  .text-type-content::before {
    content: "";
    height: calc(100% - 24px);
    float: right;
  }
  .text-type-content::after {
    content: "";
    width: 999vw;
    height: 999vw;
    position: absolute;
    box-shadow: inset calc(100px - 999vw) calc(30px - 999vw) 0 0 #fff;
    margin-left: -100px;
  }
  .btn {
    float: right;
    clear: both;
    margin-left: 10px;
    cursor: pointer;
    color: $blue;
  }
  .btn::before {
    content: "[全文]";
  }
  .exp {
    display: none;
  }
  .exp:checked + .text-type-content {
    -webkit-line-clamp: 999 !important;
  }
  .exp:checked + .text-type-content::after {
    visibility: hidden;
  }
  .el-table__body tr:hover > td .text-type-content::after {
    box-shadow: inset calc(100px - 999vw) calc(30px - 999vw) 0 0 #f5f7fa;
  }
  ::v-deep .el-table--enable-row-transition .el-table__body td {
    transition: auto;
  }
}
.hand-popover {
  padding: 14px 10px;
  .cancel-btn {
    margin-top: 16px;
    @include flex-ycb;
    .el-button {
      flex: 1;
    }
  }
}
</style>
